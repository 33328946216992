import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { Helper } from "../shared/helper";
import { ApiService } from "./api.service";


@Injectable({ providedIn: 'root' })
export class AuthService {

    public logginUser: UserModel;
    public user_details = JSON.parse(localStorage.getItem('userData'));
    private loginSubject = new BehaviorSubject<UserModel>(this.user_details);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();


    get isAuthenticated(): boolean {
        return (!this.logginUser || this.logginUser === null) ? false : true;
    }

    constructor(private _api: ApiService, private helper: Helper) { }

    // get user_detail() {
    //     return this.logginUser ? this.logginUser : new UserModel();
    // }    

    // Login
    login(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.login, parameters }).then((response) => {
                    if (response.success) {
                        this.logginUser = response.data.partnerDetail;
                        var localStorageData = {
                            _id: this.logginUser._id,         
                            token: this.logginUser.token,                            
                            email: this.logginUser.email,
                            first_name: this.logginUser.first_name,
                            last_name: this.logginUser.last_name,
                            country: this.logginUser.country,
                            country_phone_code: this.logginUser.country_phone_code,
                            phone: this.logginUser.phone,                                                       
                            picture: this.logginUser.picture,                          
                            wallet_currency_code: this.logginUser.wallet_currency_code,
                        }
                        this.helper.user_details = localStorageData;
                        localStorage.setItem('userData', JSON.stringify(localStorageData))

                        this.loginSubject.next(this.logginUser);

                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(true);
            }
        })
    }

    //logout
    logout(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.logout, parameters: parameters }).then((res) => {                    
                    if (res.success) {                        
                        this.logginUser = null;
                        this.loginSubject.next(this.logginUser);
                        localStorage.removeItem('userData')
                        localStorage.removeItem('device_token')
                        this.helper.isUpadtedlocalStorage();
                        this.helper._route.navigate(['/partner/login'])
                        resolve(true)
                    } else {
                        rejects(null)
                    }
                })
            } catch (error) {
                resolve(null);
            }
        })
    }

    // Forgot Password
    forgot_password_Email(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.forgot_password, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
    
    //reset password
    new_password(parameters): Promise<boolean>{
        return new Promise((resolve, rejects) =>{
            try{
                this._api.post({url: apiColletions.new_password,parameters}).then((response) => {
                    if(response.success){
                        resolve(true);
                    }else{
                        resolve(false);
                    }
                })
            }catch(err){
                resolve(false);
            }
        })
    }

    forgotPasswordPhone(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_otp, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
    
    // Register

    partner_register(parameters): Promise<boolean> {
        return new Promise((resolve, rejects) => {
            try{
                this._api.post({url: apiColletions.register, parameters}).then((response) => {
                    if(response.success){                                                    
                        resolve(true);
                    }else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

  

    // Refrral Code Apply

    providerRefrral(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.apply_provider_referral_code, parameters }).then((response) => {
                    if (response.success) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }
}
