export var apiColletions = {

  //completed request
  "complete_request":"/partner/complete_request",
  "request_details":"/partner/request_details",  

    // Auth
    "login": "/partner/login",
    "logout": "/partner/partner_sign_out",
    "forgot_password":"/partner/forgot_password",
    "new_password": "/partner/update_password",
    "register": "/partner/register",   
       
    // country city
    "get_country_city_list": "/get_country_city_list",
    
    //Profile
    "get_profile_detail": "/partner/get_partner_details",
    "update_partner_profile": "/partner/update_partner_details",
    "delete_partner_profile": "/partner/delete_partner",

    //Common    
    "get_provider_setting_detail": "/get_provider_setting_detail",   
    "get_language_list":"/get_language_list",

    //driver

    "add_driver":"/partner/create_partner_provider",    
    "get_driver_data":"/partner/partner_provider_list",    
    "update_driver_data":"/partner/update_provider_details",
    "get_provider_data": "/partner/get_provider_details",
    "fetch_destination_city": "/admin/fetch_destination_city",
    "fetch_service_types": "/partner/partner_fetch_service_type",
    "get_vehicle_list": "/partner/get_available_vehicle_list",
    "get_document": "/partner/partner_documents_list",
    "pranter_document_update": "/partner/pranter_document_update",
    "assign_vehicle_to_provider": "/partner/assign_vehicle_to_provider",
    "remove_vehicle_from_provider": "/partner/remove_vehicle_from_provider",

      //bank detail
      "get_bank_detail": "/partner/get_bank_details",      
      "add_bank_detail": "/partner/add_bank_details",      
      "delete_bank_detail": "/partner/delete_bank_details",
      "get_setting":"/partner/get_setting ",


    //vehicle 
    "get_vehicle_data" : "/partner/get_partner_vehicle",
    

    // vehicle            
    "get_server_time": "/get_server_time",
    "provider_add_vehicle": "/partner/create_partner_vehicle",    
    "get_provider_vehicle_detail": "/partner/vehicle_document_list_for_partner",    
    "provider_update_vehicle_detail": "/partner/update_vehicle_details",

    // Profile
    "get_provider_detail": "/get_provider_detail",
    "provider_update": "/providerupdatedetail",    
    "get_otp": "/get_otp",
    "update_password": "/update_password",

    //change vehicle
    "change_current_vehicle": "/change_current_vehicle",

    // Document Upload
    "get_user_document": "/getuserdocument",
    "upload_user_document": "/uploaduserdocument",
    "upload_vehicle_document": '/partner/vehicle_documents_update_for_partner',
    "get_provider_document": "/getproviderdocument",
    "upload_provider_document": "/uploaddocument",


    // Payment
    "get_card_list": "/payments/cards",
    "select_card": "/payments/card_selection",
    "add_card": "/payments/addcard",
    "get_payment_gateway": "/get_payment_gateway",
    "get_stripe_add_card_intent": "/payments/get_stripe_add_card_intent",
    "get_stripe_payment_intent_wallet": "/payments/get_stripe_payment_intent",
    "send_paystack_required_detail": "/payments/send_paystack_required_detail",
    "add_wallet_amount": "/add_wallet_amount",
    "delete_card": "/payments/delete_card",
    "pay_stripe_intent_payment": "/pay_stripe_intent_payment",

    // Wallet History
    "get_wallet_history": "/partner/wallet_history",

    //userhistory
    "providerhistory": "/providerhistory",
    "usertripdetail": "/usertripdetail",
    "usergettripstatus": "/usergettripstatus",
    "get_provider_info": "/get_provider_info",
    "user_submit_invoice": "/user_submit_invoice",

    //apply_promo_code
    "apply_promo_code": "/apply_promo_code",

    //Create Trip
    "create_trip": "/createtrip",
    "canceltrip": "/canceltrip",

    //walletstatus
    "change_user_wallet_status": "/change_user_wallet_status",

    //usegiverting
    "usergiverating": "/usergiverating",

    //getfuturetrip
    "getfuturetrip": "/getfuturetrip",

    //gettrippath
    "getgooglemappath": "/getgooglemappath",

    //referral_code
    "apply_provider_referral_code": "/apply_provider_referral_code",

    //delete_user
    "delete_provider": "/delete_provider",

    // earning    
    "get_web_provider_weekly_earning_detail": "/partner/earning_details",
    "get_weekly_earning_statement": "/partner/statement_earning",
    "get_partner_weekly_earning_statement": "/partner/partner_earning_details",

    // excel sheet
    "provider_history_export_excel": "/provider_history_export_excel",
    "provider_earning_export_excel": "/provider_earning_export_excel"

}
