import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { apiColletions } from "../constants/api_collection";
import { UserModel } from "../models/user.model";
import { ApiService } from "./api.service";
import { Helper } from "../shared/helper";

@Injectable({ providedIn: 'root' })
export class ProfileService {
    
    public logginUser: UserModel;
    private loginSubject = new BehaviorSubject<UserModel>(this._helper.user_details);
    loginObservable: Observable<UserModel> = this.loginSubject.asObservable();

    constructor(private _api: ApiService,private _helper:Helper) { }

    fetch_profile(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_profile_detail, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve(null);
                    }
                })
            } catch (err) {
                resolve(null);
            }
        })
    }

    update_profile(profileForm): Promise<Boolean> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.update_partner_profile, parameters: profileForm }).then((response) => {
                    if (response.success) {
                        this.logginUser = response.data.Partner_detail;
                        var localStorageData = {
                            _id: this.logginUser._id,
                            token: this.logginUser.token,
                            first_name: this.logginUser.first_name,
                            last_name: this.logginUser.last_name,
                            email: this.logginUser.email,                            
                            picture: this.logginUser.picture,                            
                        }
                        localStorage.setItem('userData', JSON.stringify(localStorageData))

                        this.loginSubject.next(this.logginUser);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                })
            } catch (err) {
                resolve(false);
            }
        })
    }

    partner_profile_delete(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
          try {
            this._api.post({ url: apiColletions.delete_partner_profile, parameters }).then((response) => {
              if (response.success) {
                resolve(response.data);
              } else {
                resolve(null);
              }
            })
          } catch (err) {
            resolve(null);
          }
        })
      }

      
    get_wallet_history(parameters): Promise<any> {
        return new Promise((resolve, rejects) => {
            try {
                this._api.post({ url: apiColletions.get_wallet_history, parameters }).then((response) => {
                    if (response.success) {
                        resolve(response.data);
                    } else {
                        resolve([]);
                    }
                })
            } catch (err) {
                resolve([]);
            }
        })
    }

}