export var PDFSIZE = 100000;

export var DEFAULT_IMAGE = {
	DEFAULT_PDF_IMG: 'assets/default_images/pdf_img.png',
	USER_PROFILE: 'assets/default_images/profile.png',
	DOCUMENT_PROFILE:'assets/default_images/uploading.png',
	USER_SQUARE: 'assets/default_images/user_square.png',
	PICKUP_ICON: 'assets/default_images/map_pin/pickup.png',
	DESTINATION_ICON: 'assets/default_images/map_pin/destination.png',
	DRIVER_ICON: 'assets/default_images/map_pin/driver.png',
	STOP_ICON: 'assets/default_images/map_pin/stops.png',
	CATEGORY: '/assets/img/default_images/category.png',
}
export var DATE_FORMAT = {
	DD_MM_YYYY_HH_MM_A: 'dd MMM yyyy hh:mm a',
	DD_MM_YYYY: 'dd MMM yyyy',
	D_MMM_H_MM_A:"d MMM - h:mm a",
	dd_mm_yyyy:'dd/MM/yyyy',
}

export var TRIP_TYPE = {
	TRIP_TYPE_NORMAL: 0,
	TRIP_TYPE_VISITOR: 1,
	TRIP_TYPE_HOTEL: 2,
	TRIP_TYPE_DISPATCHER: 3,
	TRIP_TYPE_SCHEDULE: 5,
	TRIP_TYPE_PROVIDER: 6,
	TRIP_TYPE_CORPORATE: 7,

	TRIP_TYPE_AIRPORT: 11,
	TRIP_TYPE_ZONE: 12,
	TRIP_TYPE_CITY: 13,
	TRIP_TYPE_CAR_RENTAL: 14,
	TRIP_TYPE_GUEST_TOKEN: 15,
}
export var CANCEL_REASON = {  labels: ["My Driver I couldn't connect.", 'Wrong address or location shown.', 'The eta was too long.']}

export var PROVIDER_TYPE = {
	PROVIDER_TYPE_PARTNER : 1
}

export var TRIP_STATUS = {
	RUNNING: 1,
	SCHEDULED: 2,
	COMPLETED: 3,
	CANCELLED: 4
}

export var PROVIDER_STATUS = {
	WAITING: 0,
	ACCEPTED: 0,
	ACCEPT: 1,
	COMING: 2,
	AFTER_TIME_WAITING: 3,
	ARRIVED: 4,
	STARTED: 6,
	COMPLETED: 9
}

export var PROVIDER_ACCEPTED = {
	WAITING: 0,
	ACCEPTED: 1,
	AFTER_TIME_WAITING: 3,
}

