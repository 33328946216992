import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../environments/environment";
import { Helper } from "../shared/helper";

export interface ResponseModel {
    success: boolean;
    code: string
    data: any;
}

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    is_loading = false;
    private API_URL = environment.API_URL;
    constructor(private _http: HttpClient, private translateService: TranslateService, private helper: Helper) { }
    post({ url, parameters }): Promise<ResponseModel> {
        this.is_loading = true;
        if (this.helper.user_details) {
            var headers = new HttpHeaders()
                .set('admin_id', this.helper.user_details._id)
                .set('token', this.helper.user_details.token)
                .set('type', '3');
        }
        return new Promise((resolve, rejects) => {
            try {
                var call_url = this.getBaseUrl(url) + url;
                this._http.post(call_url, parameters, { headers: headers }).toPromise().then(responseData => {
                    setTimeout(() => {
                        if (this.is_loading) {
                            this.is_loading = false;
                        }
                    }, 500);
                    if (responseData['success']) {
                        resolve({ success: responseData['success'], code: responseData['message'], data: responseData })
                    } else if (responseData['error_code'] == 4002) {
                        setTimeout(() => {
                            localStorage.removeItem('userData');
                            this.helper.isUpadtedlocalStorage();
                            this.helper._route.navigate(['/']).then(() => {
                                window.location.reload();
                            })
                        }, 500);
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    } else {
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    }
                })
            } catch (err) {
                resolve({ success: false, code: "2003", data: null });
            }
        });
    }

    get({ url, parameters }): Promise<ResponseModel> {
        this.is_loading = true;
        if (this.helper.user_details) {
            var headers = new HttpHeaders()
                .set('admin_id', this.helper.user_details._id)
                .set('token', this.helper.user_details.token)
                .set('type', '3');
        }
        return new Promise((resolve, rejects) => {
            try {
                var call_url = this.getBaseUrl(url) + url;
                this._http.get(call_url, { headers: headers }).toPromise().then(responseData => {
                    setTimeout(() => {
                        if (this.is_loading) {
                            this.is_loading = false;
                        }
                    }, 500);
                    if (!responseData) {
                        resolve({ success: true, code: '', data: null })
                    } else if (responseData['success']) {
                        resolve({ success: responseData['success'], code: responseData['message'], data: responseData })
                    } else if (responseData['error_code'] == 4002) {
                        setTimeout(() => {
                            localStorage.removeItem('userData');
                            this.helper.isUpadtedlocalStorage();
                            this.helper._route.navigate(['/']).then(() => {
                                window.location.reload();
                            })
                        }, 500);
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    } else {
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: null })
                    }
                })
            } catch (err) {
                resolve({ success: false, code: "2003", data: null });
            }
        });
    }

    getwithparams({ url, params }): Promise<ResponseModel> {
        this.is_loading = true;
        if (this.helper.user_details) {
            var headers = new HttpHeaders()
                .set('admin_id', this.helper.user_details._id)
                .set('token', this.helper.user_details.token)
                .set('type', '3');
        }
        return new Promise((resolve, rejects) => {
            try {
                var call_url = this.getBaseUrl(url) + url;

                this._http.get(call_url, { params: params, headers: headers }).toPromise().then(responseData => {
                    setTimeout(() => {
                        if (this.is_loading) {
                            this.is_loading = false;
                        }
                    }, 500);
                    if (!responseData) {
                        resolve({ success: true, code: '', data: null })
                    } else if (responseData['success']) {
                        resolve({ success: responseData['success'], code: responseData['message'], data: responseData })
                    } else if (responseData['error_code'] == 4002) {
                        setTimeout(() => {
                            localStorage.removeItem('userData');
                            this.helper.isUpadtedlocalStorage();
                            this.helper._route.navigate(['/']).then(() => {
                                window.location.reload();
                            })
                        }, 500);
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    } else {
                        resolve({ success: responseData['success'], code: responseData['error_code'], data: responseData })
                    }
                })
            } catch (err) {
                resolve({ success: false, code: "2003", data: null });
            }
        });
    }

    getBaseUrl(url){
        if(url.split("/")[1] == "payments"){
            return environment.PAYMENTS_API_URL
        }
        return environment.API_URL
    }

}
