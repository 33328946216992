import { Injectable } from "@angular/core";
import { apiColletions } from "../constants/api_collection";
import { CountryModel } from "../models/country.model";
import { ApiService } from "./api.service";

export interface Lang {
  code: string,
  name: string
  string_file_path: string
}

@Injectable({ providedIn: 'root' })
export class CommonService {

  constructor(private _api: ApiService) { }
    
  get_country_city_list():Promise<boolean>{
    return new Promise((resolve,reject)=>{
        try {
            this._api.post({url:apiColletions.get_country_city_list,parameters:{}}).then((response)=>{
                if(response.success){
                    resolve(response.data.country_list);
                }else{
                    resolve(false);
                }
            })
        } catch (error) {
            resolve(false)
        }
    })
}

  get_fetch_destination_city(parameters):Promise<any>{
    return new Promise((resolve,reject)=>{
      try {
          this._api.post({url:apiColletions.fetch_destination_city,parameters}).then((response)=>{
              if(response.success){
                  resolve(response.data);
              }else{
                  resolve(null);
              }
          })
      } catch (error) {
          resolve(null)
      }
  })
  }

  get_setting_detail(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.get_provider_setting_detail, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  
  UserAccountDelete(parameters): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.delete_provider, parameters }).then(response => {
          if (response.success) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }
  
  provider_history_export_excel(parameters  ){
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.provider_history_export_excel, parameters}).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  provider_earning_export_excel(parameters){
    return new Promise((resolve, rejects) => {
      try {
        this._api.post({ url: apiColletions.provider_earning_export_excel, parameters}).then(response => {
          if (response) {
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } catch (err) {
        resolve(null)
      }
    })
  }

  getLanguageList(): Promise<any> {
    return new Promise((resolve, rejects) => {
      try {
        this._api.get({ url: apiColletions.get_language_list ,parameters:[]}).then((res) => {

          if (res.success) {
            resolve(res.data)
          } else {
            rejects(null)
          }
        })
      } catch (error) {
        resolve(null);
      }
    })
  }
}


