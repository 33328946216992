import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  {
    icon: 'iconsminds-air-balloon-1',
    label: 'menu.vien',
    to: `${adminRoot}/vien`,
    roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      // {
      //   icon: 'simple-icon-paper-plane',
      //   label: 'menu.history',
      //   to: `${adminRoot}/history`,
      //   // roles: [UserRole.Admin],
      // },
      // {
      //   icon: 'simple-icon-paper-plane',
      //   label: 'menu.wallet-history',
      //   to: `${adminRoot}/wallet-history`,
      //   // roles: [UserRole.Admin],
      // },
      // {
      //   icon: 'simple-icon-paper-plane',
      //   label: 'menu.manage-vehicle',
      //   to: `${adminRoot}/manage-vehicle`,
      //   // roles: [UserRole.Admin],
      // },
    ],
  },
  // {
  //   icon: 'iconsminds-three-arrow-fork',
  //   label: 'menu.earning',
  //   to: `${adminRoot}/earning`,
  //    // roles: [UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-paper-plane',
  //       label: 'menu.daily-earning',
  //       to: `${adminRoot}/earning/daily-earning`,
  //     },
  //     {
  //       icon: 'simple-icon-paper-plane',
  //       label: 'menu.weekly-earning',
  //       to: `${adminRoot}/earning/weekly-earning`,
  //     },
  //     {
  //       icon: 'simple-icon-paper-plane',
  //       label: 'menu.partner-earning',
  //       to: `${adminRoot}/earning/partner-earning`,
  //     },
  //   ],
  // },
  {
    icon: 'iconsminds-bucket',
    label: 'menu.profile',
    to: `${adminRoot}/profile`,
  },
  {
    icon: 'iconsminds-bucket',
    label: 'label-title.completed-requests',
    to: `${adminRoot}/completed_requests`,
  },
  {
    icon: 'iconsminds-bucket',
    label: 'menu.manage-vehicle',
    to: `${adminRoot}/manage-vehicle`,
  },
  {
    icon: 'iconsminds-bucket',
    label: 'menu.earning',
    to: `${adminRoot}/earning`,
  },
  {
    icon: 'iconsminds-bucket',
    label: 'menu.driver',
    to: `${adminRoot}/driver`,
  },
  {
    icon: 'iconsminds-bucket',
    label: 'menu.running_requests',
    to: `${adminRoot}/running_requests`,
  },
  // {
  //   icon: 'iconsminds-bucket',
  //   label: 'menu.weekly-earning',
  //   to: `${adminRoot}/earning/weekly-earning`,
  // },
];
export default data;
